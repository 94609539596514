import React from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import "../../components/layout.css"

// import '../css/index.css'; // add some style if you want!
export default function Index({ data }) {
  const { edges: posts } = data.allMarkdownRemark
  return (
    <div className="blog-posts">
      {posts
        .filter(post => post.node.frontmatter.title.length > 0)
        .map(({ node: post }) => {
          return (
            <div className="blog-post-preview" key={post.id}>
              <h3>
                <Link to={`/asdf${post.frontmatter.path}`}>{post.frontmatter.title}</Link>
              </h3>
              <p>{post.frontmatter.date}</p>
              <p>{post.excerpt}</p>
            </div>
          )
        })}
    </div>
  )
}
export const pageQuery = graphql`
  query myBlogIndexQuery {
      allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { fields: { collection: { eq: "blog" } } }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
          }
        }
      }
    }
  }
`

